import React from 'react';
import { Link } from 'gatsby';
import { slide as Menu } from 'react-burger-menu';
import FocusLock from 'react-focus-lock';
import { HamburgerMenu, CloseIcon } from './svgs';
import styled from 'styled-components';

const NavStyles = styled.nav`
  a {
    text-align: center;
    opacity: 0.6;
    text-decoration: none !important;
    border-bottom: 5px solid transparent;
    &:hover, &:focus {
      outline: none;
      opacity: 1;
      border-bottom: 5px solid var(--dark10);
      transition: all 0.2s ease-in-out;
      &:active, &.active {
        border-color: transparent;
        background-color: transparent;
      }
    }
    &:focus {
      background-color: var(--dark10);
      opacity: 1;
      transition: all 0.2s ease-in-out;
    }
  }
  button {
    opacity: 0.6;
    &:hover, &:focus {
      opacity: 1;
      transition: all 0.2s ease-in-out;
    }
    &:focus {
      outline: 5px solid var(--dark10);
      transition: all 0.2s ease-in-out;
    }
  }
  .bm-item-list {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: min-content !important;
  }
  .bm-item {
    display: block;
    font-size: 5em;
    line-height: 2;
    &.active {
      opacity: 1;
      &:hover {
        background: transparent;
      }
    }
  }
  .bm-menu {
    background: ${props => props.theme.dark ? 'var(--hotpink)' : 'var(--hotpink)'};
  }
  .bm-burger-button {
    position: fixed;
    width: 30px;
    left: 20px;
    top: 20px;
    opacity: 60%;
    &:hover {
      opacity: 100%;
    }
    @media (max-width: 680px) {
      width: 20px;
    }
    @media (max-width: 525px) {
      left: 15px;
      top: 15px;
    }
    @media (max-width: 440px) {
      width: 15px;
    }
  }
  .bm-cross-button {
    position: absolute;
    top: 16px !important;
    right: 16px !important;
  }
`;

class NavBar extends React.Component {
  state = {
    disabled: true,
  }

  toggleLock = () => this.setState({disabled:!this.state.disabled});

  render() {
    const { theme } = this.props;
    return (
      <NavStyles theme={theme}>
        <FocusLock disabled={this.state.disabled} persistentFocus={true}>
          <Menu
            disableAutoFocus
            width={ '100vw' }
            customBurgerIcon={ <HamburgerMenu fill={ theme.dark ? 'var(--light60)' : 'var(--dark60)'} alt="Menu" /> }
            customCrossIcon={ <CloseIcon fill={ theme.dark ? 'var(--light100)' : 'var(--dark100)'} alt="Close Menu" className="close" /> }
            onStateChange={ this.toggleLock }>
            <Link to='/' activeClassName='active'>Home</Link>
            <Link to='/archive' partiallyActive={true} activeClassName='active'>Archive</Link>
            <Link to='/about' partiallyActive={true} activeClassName='active'>About</Link>
          </Menu>
        </FocusLock>
      </NavStyles>
    )
  }
}

export default NavBar;