import React from "react"
import ThemeContext from '../context/ThemeContext';
import styled, { ThemeProvider } from 'styled-components';
import NavBar from './nav';

const StylesContainer = styled.div`
  width: 100vw;
  min-height: 100vh;
  background-color: ${props => props.theme.dark ? 'var(--dark100)' : 'var(--light100)'};
  color: ${props => props.theme.dark ? 'var(--light100)' : 'var(--dark100)'};
  font-family: 'News Cycle';
  font-size: 10px;
  overflow-x: hidden;
  line-height: 1;
  a {
    color: ${props => props.theme.dark ? 'var(--light100)' : 'var(--dark100)'};
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  h1,h2,h3,h4,h5,h6 {
    font-family: 'Crimson Text';
  }
  p {
    font-size: 1.6em;
  }
`;

const ContentContainer = styled.section`
  width: 70%;
  min-width: 320px;
  margin: 0 auto;
  min-height: 100vh;
  @media (max-width: 825px) {
    width: 80%;
  }
`;

class Layout extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <ThemeContext.Consumer>
        { theme => (
          <ThemeProvider theme={theme}>
            <StylesContainer>
              <NavBar theme={theme}/>
                <ContentContainer>{children}</ContentContainer>
            </StylesContainer>
          </ThemeProvider>
        )}
      </ThemeContext.Consumer>
    )
  }
}

export default Layout;
